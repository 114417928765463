@use "sass:math";
@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
        opacity: 1;
    }
    &:-moz-placeholder {
        @content;
        opacity: 1;
    }
    &::-moz-placeholder {
        @content;
        opacity: 1;
    }
    &:-ms-input-placeholder {
        @content;
        opacity: 1;
    }
}
@mixin rtl {
    html[dir='rtl'] & {
        @content;
    }
}
@mixin hover {
    @media (hover: hover) {
        &:hover {
            @content;
        }
    }
}
@mixin hover-active {
    @media (hover: hover) {
        &:hover {
            @content;
        }
    }
    &.active {
        @content;
    }
}
@function rem($size) {
    @if $size == 1 {
        @return #{$size}px;
    }
    $remSize: math.div($size, $baseFontSize);
    @return #{$remSize}rem;
}
@function -rem($size) {
    @if $size == 1 {
        @return -#{$size}px;
    }
    $remSize: math.div($size, $baseFontSize);
    @return -#{$remSize}rem;
}
@mixin desktop {
    @media screen and (min-width: 578px) {
        @content;
    }
}
@mixin mobile {
    @media screen and (max-width: 577px) {
        @content;
    }
}
@function per($size, $guide: 1160) {
    $per: math.div($size, $guide) * 100;
    @return #{$per + '%'};
}
@mixin retina {
    @media(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        @content;
    }
}
@function c($color, $opacity: 1) {
    @if $opacity == 1 {
        @return var(--#{$color});
    } @else {
        @return rgba(var(--#{$color}-rgb), $opacity);
    }
}
@function ch($color) {
    @return var(--#{$color}-hover);
}
@function sc($color, $opacity: 1) {
    @if $opacity == 1 {
        @return var(--#{$color}-sc);
    } @else {
        @return rgba(var(--#{$color}-sc-rgb), $opacity);
    }
}
@function f($size, $line: $size + 4) {
    @return rem($size) / rem($line);
}
@function size($size, $name) {
    @return var(--ui-kit-#{$size}-#{$name});
}
@mixin getSizes {
    @each $size, $props in $uiKitSizes {
        &.s-#{$size} {
            --height: #{size($size, 'height')};
            --padding: #{size($size, 'padding')};
            --font-size: #{size($size, 'fontSize')};
            --width: #{size($size, 'proportionalWidth')};
            --border-radius: #{size($size, 'borderRadius')};
        }
    }
}
@mixin over($n1, $n2) {
    --#{$n1}: var(--#{$n2});
    --#{$n1}-hover: var(--#{$n2}-hover);
    --#{$n1}-sc: var(--#{$n2}-sc);
    --#{$n1}-rgb: var(--#{$n2}-rgb);
    --#{$n1}-sc-rgb: var(--#{$n2}-sc-rgb);
}
@function set-lightness($color, $amount) {
    @if (lightness($color) > 50) {
        @return darken($color, $amount);
    } @else {
        @return lighten($color, $amount);
    }
}
@function set-color($color, $color-dark: #000, $color-light: #ffffff) {
    @if (lightness($color) > 50) {
        @return $color-dark;
    } @else {
        @return $color-light;
    }
}
