@import "mixins";
$f: 'Realist', sans-serif;
$baseFontSize: 22;
$hero: #F7C03F;

$uiKitSizes: (
    small: (
        height: 32,
        padding: 14,
        fontSize: 12,
        borderRadius: 6,
        proportionalWidth: 110,
    ),
    default: (
        height: 40,
        padding: 20,
        fontSize: 14,
        borderRadius: 8,
        proportionalWidth: 110,
    ),
    medium: (
        height: 44,
        padding: 20,
        fontSize: 14,
        borderRadius: 8,
        proportionalWidth: 110,
    ),
    big: (
        height: 48,
        padding: 20,
        fontSize: 14,
        borderRadius: 8,
        proportionalWidth: 110,
    ),
    extra-big: (
        height: 52,
        padding: 20,
        fontSize: 14,
        borderRadius: 8,
        proportionalWidth: 110,
    ),
    extra-large: (
        height: 60,
        padding: 24,
        fontSize: 18,
        borderRadius: 12,
        proportionalWidth: 110,
    ),
);

$c: (
    brand: $hero,
    b: #000,
    warning: #F16D00,
    confirm: #4caf50,
    danger: #ff3866,
);

@each $name, $color in $c {
    $c: map-merge($c, (#{$name + '-hover'}: set-lightness($color, 5)));
    $c: map-merge($c, (#{$name + '-light'}: lighten($color, 5)));
    $c: map-merge($c, (#{$name + '-dark'}: lighten($color, 5)));
    $c: map-merge($c, (#{$name + '-sc'}: set-color($color)));
    $c: map-merge($c, (#{$name + '-rgb'}: #{red($color), green($color), blue($color)}));
    $c: map-merge($c, (#{$name + '-sc-rgb'}: #{red(set-color($color)), green(set-color($color)), blue(set-color($color))}));
}
