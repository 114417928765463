@import "magic";
* {
    margin: 0;
    border: 0;
    padding: 0;
    outline: 0;
    border-radius: 0;
    background: none;
    vertical-align: top;
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-tap-highlight-color: transparent;
    &, &:before, &:after {
        box-sizing: border-box;
    }
}
a {
    text-decoration: none;
    transition: 300ms color, 300ms background;
}
li {
    display: block;
}
input, select, button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
input:not([type="button"]):not([type="submit"]), select {
    @include mobile {
        font-size: 16px !important;
        @include placeholder {
            font-size: 16px !important;
        }
    }
}
.hide {
    display: none;
}
.pointer {
    cursor: pointer;
}
.pointer-events-none {
    pointer-events: none;
}
.guide {
    max-width: rem(1160 + 40);
    width: 100%;
    padding: 0 rem(20);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
html {
    -webkit-text-size-adjust: 100%;
    user-select: none;
    font: 300 #{$baseFontSize}px / 1.7 $f;
    background: #2F2F2F;
    color: #fff;
    @include desktop {
        @media screen and (max-width: 1200px) {
            font-size: 18px;
        }
        @media screen and (max-width: 982px) {
            font-size: 14px;
        }
        @media screen and (max-width: 780px) {
            font-size: 10px;
        }
    }
    &, body, #root {
        width: 100%;
        min-height: 100%;
        display: flex;
    }
    #root {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 1fr auto;
        main {
            display: flex;
            flex-direction: column;
        }
    }
}
.header-mounted {
    #root {
        padding: rem(120) 0 rem(50);
        @include mobile {
            padding: rem(80) 0 rem(30);
        }
    }
}
.page-layout {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: rem(100);
    padding: 0 0 rem(100);
    @include mobile {
        grid-row-gap: rem(80);
        padding: 0 0 rem(80);
    }
}
.sub-page-holder {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: rem(50);
    @include mobile {
        grid-row-gap: rem(30);
    }
    &.gap-big {
        grid-row-gap: rem(100);
        @include mobile {
            grid-row-gap: rem(80);
        }
    }
}
