@import "magic";
@import "reset";
.border-animate {
    --color: #{$hero};
    $hero: var(--color);
    background-image: linear-gradient(90deg, $hero 100%, $hero 0), linear-gradient(180deg, $hero 100%, $hero 0), linear-gradient(90deg, $hero 100%, $hero 0), linear-gradient(180deg, $hero 100%, $hero 0);
    background-repeat: no-repeat;
    background-size: 0 rem(1), rem(1) 0, 0 rem(1), rem(1) 0;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(.19, 1, .22, 1);
    .animate & {
        animation-name: border;
        animation-delay: 0.4s;
        animation-duration: 2s;
    }
    &.border-white {
        --color: white;
    }
}
:root {
    @each $name, $color in $c {
        --#{$name}: #{$color};
    }
    @each $size, $props in $uiKitSizes {
        @each $name, $value in $props {
            --ui-kit-#{$size}-#{$name}: #{rem($value)};
        }
    }
}
@keyframes border {
    0% {
        background-size: 0 rem(1), rem(1) 0, 0 rem(1), rem(1) 0
    }

    25% {
        background-size: 100% rem(1), rem(1) 0, 0 rem(1), rem(1) 0
    }

    50% {
        background-size: 100% rem(1), rem(1) 100%, 0 rem(1), rem(1) 0
    }

    75% {
        background-size: 100% rem(1), rem(1) 100%, 100% rem(1), rem(1) 0
    }

    to {
        background-size: 100% rem(1), rem(1) 100%, 100% rem(1), rem(1) 100%
    }
}
